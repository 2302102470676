<template>
  <vue-apex-charts
    height="350"
    type="donut"
    :options="chartOptions"
    :series="count"
  />
</template>

<script>
import VueApexCharts from 'vue-apexcharts'

export default {
  components: {
    VueApexCharts,
  },
  props: {
    count: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      chartOptions: {
        legend: {
          show: true,
          position: 'bottom',
          fontSize: '12px',
          fontFamily: '"Inter", sans-serif',
          display: 'block',
        },
        chart: {
          type: 'donut',
        },
        colors: ['#EC008C', '#FF8ACF', '#FFC1E6'],
        labels: ['Total Anggaran', 'Total Pengeluaran', 'Total Sisa'],
        dataLabels: {
          enabled: true,
        },
        responsive: [
          {
            breakpoint: 992,
            options: {
              chart: {
                height: 380,
              },
              legend: {
                position: 'bottom',
              },
            },
          },
          {
            breakpoint: 576,
            options: {
              chart: {
                height: 320,
              },
              plotOptions: {
                pie: {
                  donut: {
                    labels: {
                      show: true,
                      name: {
                        fontSize: '1.5rem',
                      },
                      value: {
                        fontSize: '1rem',
                      },
                      total: {
                        fontSize: '1.5rem',
                      },
                    },
                  },
                },
              },
              legend: {
                show: true,
              },
            },
          },
        ],
      },
    }
  },
}
</script>
